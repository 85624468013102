import { Controller } from "@hotwired/stimulus";

// Ejemplo de uso:
// window.dispatchEvent(
//   new CustomEvent("toast", { detail: { content: "¡Hola, usuarix!" } })
// );
export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.toast_event = this._toast_event.bind(this);

    window.addEventListener("toast", this.toast_event);
  }

  disconnect() {
    window.removeEventListener("toast", this.toast_event);
  }

  _toast_event(event) {
    this.contentTarget.innerText = event.detail.content;
    this.set(true);

    if (this.interval) {
      clearTimeout(this.interval);
    }
    this.interval = setTimeout(() => {
      this.set(false);
      this.interval = null;
    }, 3000);
  }

  set(show) {
    if (show) {
      this.element.classList.remove("hide");
      this.element.classList.add("show");
    } else {
      this.element.classList.add("hide");
      this.element.classList.remove("show");
    }
  }
}
