import { CartBaseController } from "./cart_base_controller";

/*
 * Replaces checkout.js.
 *
 * When the customer is redirected back from the approval URL, there's
 * three params attached to the URL.  We need paymentId and PayerID to
 * execute the payment and later capture it via IPN.  The token can be
 * discarded.
 */
export default class extends CartBaseController {
  async connect() {
    if (this.params.PayerID === undefined) return;

    this.site = window.site;
    this.element.innerHTML = this.site.i18n.cart.layouts.paypal.confirming;

    fetch(this.executeURL)
      .then(
        (r) =>
          (this.element.innerHTML =
            this.site.i18n.cart.layouts.paypal[r.ok ? "confirmed" : "failure"])
      )
      .catch(
        (e) =>
          (this.element.innerHTML = this.site.i18n.cart.layouts.paypal.failure)
      );
  }

  /*
   * Convert URL params to Object
   *
   * @return [Object]
   */
  get params() {
    if (this._params) return this._params;

    this._params = Object.fromEntries(
      decodeURIComponent(window.location.search.replace("?", ""))
        .split("&")
        .map((x) => x.split("="))
    );

    return this._params;
  }

  /*
   * URL to contact the store and execute the payment.
   */
  get executeURL() {
    return [
      window.spree.host,
      "paypal",
      "execute",
      this.params.orderId,
      this.params.paymentId,
      this.params.PayerID,
    ].join("/");
  }
}
